<template>
  <div class="module-page">
    <div v-if="!loading" class="content-wrap">
      <div class="title">
        <div></div>
        <span>{{ $route.query.name }}</span>
      </div>
      <div class="course-wrap">
        <template v-if="moduleList.length > 0">
          <div class="course-list">
            <div class="course-item" v-for="(item,index) in moduleList" :key="index" @click="toNavInfo(item)">
              <img v-if="item.pc_cover1" class="cover" :src="item.pc_cover1" />
              <div v-else class="cover">
                <default-cover />
              </div>
              <div class="info-wrap">
                <div class="title">{{ item.name }}</div>
                <div class="info">
                  <span>{{ item.virtually_pay_num !== '' && item.virtually_pay_num !== '0' ? item.virtually_pay_num : item.user_count }}人已报名</span>
                  <p v-if="item.is_free === 1">免费</p>
                  <p v-else class="price">￥{{ item.price }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="total > 0" class="page-wrap">
            <el-pagination
              background
              layout="prev, pager, next"
              :current-page="queryParams.page"
              :page-size="queryParams.limit"
              :total="total"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
            <div class="total-wrap">共 {{ total }} 条</div>
          </div>
        </template>
        <empty-data v-else />
      </div>
    </div>
    <loading :is-load="loading" />
  </div>
</template>

<script>
import * as courseService from '@/service/course-service';
import emptyData from '@/views/modules/empty-data';
export default {
  name: 'moduleList',
  components: {
    emptyData
  },
  data() {
    return {
      loading: false,
      queryParams: {
        limit: 12,
        page: 1
      },
      total: 0,
      moduleList: [] // 模块列表
    };
  },
  mounted() {
    this.getModuleList();
  },
  methods: {
    /**
     * 查询模块列表
     * */
    getModuleList() {
      const data = {
        course_type: '1',
        module_id: this.$route.query.id,
        ...this.queryParams
      };
      this.loading = true;
      courseService.getCourseSearch(data).then(res => {
        this.loading = false;
        if (res.code === 1) {
          this.moduleList = res.data.list || [];
          this.total = res.data.total || 0;
        }
      });
    },
    handleCurrentChange(val) {
      this.queryParams.page = val;
      this.getModuleList();
    },
    /**
     * 跳转详情
     * course_type 1=普通课程 2=直播课 0=套课
     * */
    toNavInfo(info) {
      const path = info.course_type === '0' ? '/packageDetail' : '/courseDetail';
      this.$router.push({
        path: path,
        query: {
          id: info.id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.module-page {
  padding: 20px 0 55px;
  .content-wrap {
    display: flex;
    flex-direction: column;
    .title {
      display: flex;
      align-items: center;
      line-height: 1;
      margin-bottom: 26px;
      div {
        width: 4px;
        height: 21px;
        background: #2869F5;
        border-radius: 1px;
        margin-right: 12px;
      }
      span {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
      }
    }
    .course-list {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      .course-item {
        width: 285px;
        height: 250px;
        background: #FFFFFF;
        border-radius: 8px;
        cursor: pointer;
        overflow: hidden;
        line-height: 1;
        margin: 0 20px 20px 0;
        transition: all .4s;
        .cover {
          width: 285px;
          height: 176px;
        }
        .info-wrap {
          padding: 0 16px 0 15px;
          .title {
            margin: 14px 0 10px;
            font-size: 18px;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
              font-size: 12px;
              font-weight: 400;
              color: #666666;
            }
            p {
              font-size: 18px;
              color: #E22323;
            }
            .price {
              font-weight: bold;
            }
          }
        }

      }
      .course-item:nth-child(4n) {
        margin-right: 0;
      }
      .course-item:hover {
        box-shadow: 2px 4px 9px 0px rgba(40,105,245,0.09);
        transform: translateY(-5px);
      }
    }
    .page-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 48px;
      ::v-deep .el-pagination.is-background .btn-next, ::v-deep .el-pagination.is-background .btn-prev, ::v-deep .el-pagination.is-background .el-pager li {
        background-color: #FFFFFF;
      }
      ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
        background: #2869F5;
      }
      .total-wrap {
        margin-left: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
</style>
